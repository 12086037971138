"use client";

import type React from "react";
import { useEffect, useState } from "react";
import Link from "next/link";
import { decodeJwt, type JWTPayload } from "jose";
import { ROUTES } from "@/app/lib/routes";

const AuthButton: React.FC = () => {
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	useEffect(() => {
		// Read the environment variable
		const supabaseUrl = process.env.NEXT_PUBLIC_SUPABASE_URL;

		// Extract the subdomain from the URL
		const subdomain = new URL(supabaseUrl!).hostname.split(".")[0];

		const cookieName = `sb-${subdomain}-auth-token=`;
		const cookie = document.cookie
			.split("; ")
			.find((c) => c.startsWith(cookieName));
		let rawToken = cookie ? cookie.replace(cookieName, "") : null;

		if (rawToken) {
			try {
				// Remove the "base64-" prefix if present
				if (rawToken.startsWith("base64-")) {
					rawToken = rawToken.replace("base64-", "");
				}

				// Decode the token from Base64
				const decodedBase64 = atob(rawToken);

				// Parse the decoded Base64 string as JSON
				const tokenData = JSON.parse(decodedBase64);

				// Extract the actual JWT from the `access_token` field
				const jwt = tokenData.access_token;

				// Decode the extracted JWT using jose
				const decodedJwt: JWTPayload = decodeJwt(jwt);
				console.log("Decoded JWT:", decodedJwt);

				if (decodedJwt.exp && typeof decodedJwt.exp === "number") {
					// Add a 30-second buffer to current time (in seconds)
					const currentTimeWithBuffer = Math.floor(Date.now() / 1000);
					const isExpired = decodedJwt.exp < currentTimeWithBuffer; // Check if token is expired
					setIsLoggedIn(!isExpired);
				} else {
					console.error("Invalid expiration field in JWT");
					setIsLoggedIn(false);
				}
			} catch (error) {
				console.error("Error decoding token:", error);
				setIsLoggedIn(false);
			}
		} else {
			setIsLoggedIn(false);
		}
	}, []);

	return isLoggedIn ? (
		<Link
			href={ROUTES.DASHBOARD}
			className="text-navy-blue-500 hover:text-navy-blue-700 border border-navy-blue-500 px-3 py-2 rounded-md text-sm font-medium"
		>
			Dashboard
		</Link>
	) : (
		<Link
			href="/login"
			className="text-gray-500 hover:text-gray-700 px-3 py-2 rounded-md text-sm font-medium"
		>
			Sign In
		</Link>
	);
};

export default AuthButton;
