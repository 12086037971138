"use client";

import React from "react";
import Link from "next/link";
import Image from "next/image";
import {
	Disclosure,
	DisclosureButton,
	DisclosurePanel,
} from "@headlessui/react";
import { usePathname } from "next/navigation";
import AuthButton from "@/components/auth/AuthButton";

const publicNavigation = [
	{ name: "Home", href: "/", current: true },
	{ name: "Features", href: "/features", current: false },
	{ name: "How It Works", href: "/how-it-works", current: false },
	{ name: "Pricing", href: "/pricing", current: false },
	{ name: "About", href: "/about", current: false },
];

const DEFAULT_LOGO = "/FireCompli-logo.png";

function classNames(...classes: (string | undefined | null | false)[]): string {
	return classes.filter(Boolean).join(" ");
}

export default function PublicNavbar() {
	const pathname = usePathname();

	return (
		<Disclosure as="nav" className="bg-white shadow">
			{({ open }) => (
				<>
					<div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
						<div className="relative flex h-16 justify-between">
							<div className="flex flex-1 items-center justify-center sm:justify-start">
								<Image
									src={DEFAULT_LOGO}
									alt="FireCompli Logo"
									width={32}
									height={32}
									className="rounded-full"
								/>
								<div className="hidden sm:ml-6 sm:flex sm:space-x-8">
									{publicNavigation.map((item) => (
										<Link
											key={item.name}
											href={item.href}
											className={classNames(
												pathname === item.href
													? "border-navy-blue-500 text-gray-900"
													: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
												"inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium",
											)}
										>
											{item.name}
										</Link>
									))}
								</div>
							</div>
							<div className="absolute inset-y-0 right-0 flex items-center pr-2">
								<AuthButton />
							</div>
						</div>
					</div>

					<DisclosurePanel className="sm:hidden">
						<div className="space-y-1 pb-4 pt-2">
							{publicNavigation.map((item) => (
								<DisclosureButton
									key={item.name}
									as="a"
									href={item.href}
									className={classNames(
										pathname === item.href
											? "bg-blue-50 border-blue-500 text-blue-700"
											: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700",
										"block border-l-4 py-2 pl-3 pr-4 text-base font-medium",
									)}
								>
									{item.name}
								</DisclosureButton>
							))}
							<DisclosureButton
								as="a"
								href="/signin"
								className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
							>
								<AuthButton />
							</DisclosureButton>
						</div>
					</DisclosurePanel>
				</>
			)}
		</Disclosure>
	);
}
